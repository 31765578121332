<script>

import ManualEntry from '../../discount_cost_pool/form/manual_entry_form.vue';
import request from '../../../../../../utils/request';

export default {
  extends: ManualEntry,
  data() {
    return {
      rule: [],
    };
  },

  mounted() {

  },
  methods: {
    afterReload() {
      this.formConfig.selectRow[0].totalFee = this.formConfig.selectRow[0].adjustFee;
      this.setValue(this.formConfig.selectRow[0]);
      this.rule.map((v) => {
        const rowData = v;
        rowData.props.disabled = true;
        if (rowData.field === 'totalFee' || rowData.field === 'remarks' || rowData.field === 'files') {
          rowData.props.disabled = false;
        }
        return rowData;
      });
    },
    // 提交
    async submit() {
      if (!this.getFormData()) return false;
      const params = {
        ...this.formConfig.selectRow[0],
        ...this.getFormData(),
        adjustFee: this.getFormData().totalFee,
        uniqueDictCode: 'unique_rebate_code',
      };
      delete params.list;
      const path = '/dms/dms/m/rebateFeePool/updateBpm';
      // return false;
      request
        .post(path, params)
        .then((res) => {
          if (res.success) {
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      return true;
    },
  },
};
</script>
