<script>
import TotalAdjust from '../../discount_cost_pool/form/total_adjust.vue';
import request from '../../../../../../utils/request';

export default {
  extends: TotalAdjust,
  data() {
    return {
      rule: [],
      dictCodeList: {},
    };
  },
  components: {
  },

  methods: {
    afterReload() {
      this.formConfig.selectRow[0].balance = this.formConfig.selectRow[0].beforeAvailableFee || 0;
      this.setValue(this.formConfig.selectRow[0]);
      this.rule.map((v) => {
        const rowData = v;
        rowData.props.disabled = true;
        if (rowData.field === 'adjustFee' || rowData.field === 'remarks' || rowData.field === 'files') {
          rowData.props.disabled = false;
        }
        return rowData;
      });
      this.changeAdjustFee(this.formConfig.selectRow[0].adjustFee);
    },
    // 提交
    submit() {
      if (!this.getFormData()) return false;
      const params = {
        ...this.formConfig.selectRow[0],
        ...this.getFormData(),
        uniqueDictCode: 'unique_rebate_code',
      };
      delete params.list;
      const path = '/dms/dms/m/rebateFeePool/updateBpm';
      request
        .post(path, params)
        .then((res) => {
          if (res.success) {
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
    },
  },
};
</script>
